// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.three {
  filter: brightness(108%) contrast(94%) sepia(5%) saturate(115%);
}
`, "",{"version":3,"sources":["webpack://./src/threejs/colorBalance.css"],"names":[],"mappings":"AAAA;EACE,+DAA+D;AACjE","sourcesContent":[".three {\n  filter: brightness(108%) contrast(94%) sepia(5%) saturate(115%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
